"use client";
import AboutUsSection from "../components/home/about-us/AboutUsSection";
import CallToActionSection from "../components/home/call-to-action/CallToActionSection";
import CollaboratedCompanySection from "../components/home/collaborated-company/CollaboratedCompanySection";
import EventSection from "@/components/home/event/EventSection";
import FacultySection from "../components/home/faculty/FacultySection";
import HeroSection from "../components/home/hero/HeroSection";
import KeyFeaturesSection from "../components/home/key-features/KeyFeaturesSection";
import LeadFormSection from "../components/home/lead-form/LeadFormSection";
import OurCoursesSection from "../components/home/our-courses/OurCoursesSection";
import SuccessStorySection from "../components/home/success-stories/SuccessStorySection";
import FreeCourses from "../components/home/free-courses/FreeCourses";
import Founder from "../components/home/founder/Founder";
import Internship from "@/components/home/internship/Internship";
import WhatsAppButton from "@/components/common/WhatsAppButton";
import DownloadOurApp from "@/components/common/DownloadOurApp";
import NewsChannel from "@/components/home/news/NewsChannel";
import CustomHead from "@/utils/CustomHead";
import { Slider } from "@/components/home/hero-slider/Slider";
import { CardReveal } from "@/components/home/cards/CardReveal";
import RequestCallback from "@/components/common/RequestCallback";
import { useState, useEffect } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";


export default function page() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Set a timeout to delay the popup display
    const timeout = setTimeout(() => {
      setShowPopup(true);
    }, 1000); // Display popup after 1 second

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div>
        <Slider />
        {/* <HeroSection /> */}
        <KeyFeaturesSection />
        <AboutUsSection />
        <EventSection />
        <CollaboratedCompanySection />
        <OurCoursesSection />
        <LeadFormSection />
        <FreeCourses />
        <FacultySection />
        {/* <CardReveal /> */}
        {/* <NewsChannel /> */}
        <CallToActionSection />
        {/* <Founder /> */}
        <SuccessStorySection />
        {/* <Internship/> */}
        <WhatsAppButton />
        <DownloadOurApp />
        <RequestCallback />
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="w-80 md:w-96 bg-[#fde68a] h-80 rounded-2xl">
            <h5 className="font-Poppins text-center mt-6 text-xl font-bold">Special Puja Offers!</h5>
            <p className="text-center font-Poppins  mt-10">Flat <span className="font-bold text-xl text-red-500">25%</span> Discount On <span className="text-xl text-blue-700 font-bold">Full Stack Web Development, Digital Marketing & Content Writing</span> Course</p>
            <p className="text-center font-Poppins mt-10">Use Coupon <span className="font-bold">PUJO1431</span> to Claim the Deal From Our App</p>
            <button onClick={() => setShowPopup(false)} className="fixed top-32 md:top-64 right-1/2 text-blue-200">
              <IoCloseCircleOutline size={30} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
