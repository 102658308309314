import React from "react";
import { FaFacebookF, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { TypewriterEffect } from "../../ui/TypeWriterEffect";

import Link from "next/link";
import TransparentButton from "@/components/layout/Button/TransparentButton";

const HeroSection = () => {
  const words = [
    {
      text: "Your",
    },
    {
      text: "Online",
    },
    {
      text: "Finishing",
    },
    {
      text: "School",
    },
    {
      text: "Is",
    },
    {
      text: "Vidyastu.",
      className: "text-orange-200 dark:text-orange-400",
    },
  ];
  return (
    <div className="w-full h-[450px] md:h-[600px] bg-cover bg-center bg-hero-pattern">
      <div className="w-full h-[450px] md:h-[600px] bg-slate-700/30 backdrop-brightness-[0.4]">
        {/* main content */}
        <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
          {/* social icons */}
          <div className="flex justify-end">
            <div className="flex justify-center items-center w-[30px] h-[30px] bg-green-600 rounded-full">
              <Link href="https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20your%20website.%22&type=phone_number&app_absent=0">
                <FaWhatsapp className="text-white text-lg" />
              </Link>
            </div>
            <div className="flex justify-center items-center w-[30px] h-[30px] bg-blue-600 rounded-full mx-2">
              <Link
                href="https://www.facebook.com/vidyastueducation"
                target="_blank"
              >
                <FaFacebookF className="text-white text-lg" />
              </Link>
            </div>
            <div className="flex justify-center items-center w-[30px] h-[30px] bg-red-600 rounded-full">
              <Link
                href="https://www.youtube.com/@vidyastu1787"
                target="_blank"
              >
                <FaYoutube className="text-white text-lg" />
              </Link>
            </div>
          </div>

          {/* hero content */}
          <div className="text-center mt-32 font-Poppins font-semibold">
          <TypewriterEffect words={words} />
            {/* <p className="text-2xl md:text-6xl text-white">
              ENROLL.
              <span className="text-[#FFD966]"> LEARN.</span> CREATE
            </p> */}
            <h1 className="text-white text-sm md:text-xl text-center mt-7 font-light">
            Vidyastu empowers careers with high-quality, interactive courses, currently having{" "}
              <span className="text-sm md:text-xl text-[#FFD966]">9500+</span>{" "}
              students.
            </h1>
            <div className="text-center mt-8">
              <TransparentButton href="/about" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
