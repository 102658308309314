import OurCourseCards from "./OurCourseCards";
import CallToActionButton from "@/components/layout/Button/CallToActionButton";

const OurCoursesSection = () => {
  return (
    <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
      <div className="mb-6 px-10 md:px-0 lg:-ml-36">
        <h2 className="text-2xl font-semibold text-colorHeading">
          POPULAR COURSES
        </h2>
        <div className="underline my-1"></div>
        <p className="my-4 text-lg md:text-base text-[#1e86af] font-semibold font-Poppins leading-10">
          Pick A Course To Get Started
        </p>
      </div>
      <div className="flex flex-col justify-around items-center">
        <OurCourseCards />
      </div>
      <div className="text-center py-3 mt-4">
        <CallToActionButton btnText="View All Courses" link="/courses" />
      </div>
    </div>
  );
};

export default OurCoursesSection;
