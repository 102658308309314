import React from "react";
import FeatureCard from "../../common/FeatureCard";
import FeaturesList from "./FeaturesList";

const KeyFeaturesSection = () => {
  return (
    <div className="bg-bg-pattern bg-cover bg-center w-full h-[480px] md:h-[360px] flex justify-center items-center">
      {/* main content */}
      <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56 -mt-24 md:-mt-36">
        <div className="relative bg-[#23AF84] rounded-md p-4 md:p-10  text-center">
          <h2 className="font-Poppins text-yellow font-semibold md:tracking-wide text-sm md:text-2xl mb-4">
            BEST PLATFORM TO{" "}
            <span className="font-Poppins text-white font-bold">UPGRADE </span>
            YOUR SKILL
          </h2>
          <FeaturesList />
        </div>
      </div>
    </div>
  );
};

export default KeyFeaturesSection;
